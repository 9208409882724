import { useState, useCallback, useEffect, useContext } from "react";
import { useHttpClient } from "./http-hook";
import { loginPath } from "../routes";

export const useAuth = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [token, setToken] = useState(false);
  const [user, setUser] = useState();

  const login = useCallback((user, token) => {
    setToken(token);
    setUser(user);
    localStorage.setItem(
      "userData",
      JSON.stringify({ user: user, token: token })
    );
  }, []);

  const logout = useCallback(async () => {
    const storedData = JSON.parse(localStorage.getItem("userData") || []);

    await sendRequest(`logout`, "POST", null, [], {
      Authorization: "Bearer " + storedData?.token
    });

    setToken(null);
    setUser(null);

    localStorage.removeItem("userData");

    window.location.replace(loginPath);
  }, []);

  const checkAuth = useCallback(() => {
    if (!user) {
      window.location.replace(loginPath);
      return false;
    }

    return true;
  }, [user]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));

    if (storedData && storedData.token) {
      login(storedData.user, storedData.token);
    }
  }, [login]);

  return {
    user,
    token,
    login,
    logout,
    checkAuth
  };
};
