import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import statusHostingIcon from "../../../assets/hosting-stats.svg";
import { useHttpClient } from "../../../hooks/http-hook";

const HostingSidemenu = props => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [items, setItems] = useState(null);

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    const responseData = await sendRequest(`hosts-status`);
    if (responseData) {
      setItems(responseData || []);
    }
  };

  return (
    <li className="status-tab qMenu2 pull-out">
      <div className="pull-out-tab d-flex align-items-center">
        <Link to="/statusy-hostingow">
          <img src={statusHostingIcon} />
          <div className="d-block">
            Statusy
            <br />
            hostingów
          </div>
        </Link>
        <div className="pull-out-content">
          <table className="table table-bordered table-striped table-responsive-md table-hover table-primary table-condensed">
            <tbody>
              {items?.map((i, key) => (
                <tr key={"side-hosting-" + i.name + "-" + key}>
                  <td>{i.name}</td>
                  <td>
                    {i.active ? (
                      <i className="fa fa-check-circle" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-times-circle" aria-hidden="true"></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </li>
  );
};

export default HostingSidemenu;
